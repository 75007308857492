<template>
  <Tree :value="data" :selectionMode="checked" class="tree"></Tree>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: []
    },
    checked: {
      type: String,
      default: 'checkbox'
    }
  }
}
</script>

<style scoped>
.tree {
  margin: 0 2rem;
  width: 350px;
  margin-bottom: .5rem;
}
</style>